/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, HashRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
// import { NewAuthLayout } from '../pages/AuthLayout'
import { App } from '../App'
import { useIsAuthenticated } from "@azure/msal-react";
import { useAuthRedux } from '../modules/auth/redux/useAuthRedux'
import { useSetupAuthProps } from '../modules/auth/hooks/hooks'
import { LicenseInfo } from '@mui/x-license';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const authInitiated = useSetupAuthProps()
  const isAuthenticated = useIsAuthenticated();
  const { accessToken } = useAuthRedux();
  LicenseInfo.setLicenseKey('a35e1f84ae95b6cc6380f65d2b95a959Tz05MzY2OCxFPTE3NTcwOTM4MjAwMDAsUz1wcm8sTE09cGVycGV0dWFsLFBWPWluaXRpYWwsS1Y9Mg==');

  return (
    <HashRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          {isAuthenticated && accessToken && (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/homepage' />} />
            </>
          )}
          {/* {!isAuthenticated && authInitiated !== 'initiated' && (
            <>
              <Route path='/' element={<NewAuthLayout />} />
              <Route path='*' element={<Navigate to='/' />} />
            </>
          )} */}
        </Route>
      </Routes>
    </HashRouter>
  )
}

export { AppRoutes }
