import MuiPagination from '@mui/material/Pagination';
import {
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
    GridFooterContainer,
    gridPageSelector
} from '@mui/x-data-grid-pro';

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <MuiPagination
            color="secondary"
            shape="rounded"
            showFirstButton
            showLastButton
            sx={{
                '.MuiTablePagination-menuItem.Mui-selected': {
                    backgroundColor: '#6993FF'
                },
            }}
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}

export function CustomFooter() {
    return (
        <GridFooterContainer>
            <CustomPagination />
            <GridPagination />
        </GridFooterContainer>
    );
}